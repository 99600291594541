"use client";

import { useState, useRef } from "react";
import { createClient } from "@/utils/supabase-browser";
import {
  Dialog,
  DialogTitle,
  DialogDescription,
  DialogBody,
  DialogActions,
} from "@/app/_components/ui/dialog";
import {
  Fieldset,
  Field,
  Label,
  FieldGroup,
} from "@/app/_components/ui/fieldset";
import { Text } from "@/app/_components/ui/text";
import { Switch, SwitchField } from "@/app/_components/ui/switch";
import { SubmitButton } from "@/app/_components/form/SubmitButton";
import Notice from "@/app/_components/ui/notice";
import { login } from "@/actions/auth";
import { Input } from "@/app/_components/ui/input";
import { Button } from "@/app/_components/ui/button";
import { useRouter } from "next/navigation";

const Login = () => {
  const supabase = createClient();
  const router = useRouter();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [helperText, setHelperText] = useState({ error: false, text: "" });
  const [loadingSignIn, setLoadingSignIn] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
   const [mfaRequired, setMfaRequired] = useState(false);
  const [mfaSession, setMfaSession] = useState(null); // To track MFA session

  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const forgotPasswordEmailRef = useRef<HTMLInputElement>(null);

  const handleLogin = async () => {
    setLoadingSignIn(true);

    const { error } = await login({
      email: emailRef.current?.value as string,
      password: passwordRef.current?.value as string,
    });

    if (error) {
      setHelperText({
        error: true,
        text: error.message,
      });
      setLoadingSignIn(false);
    } 
    else {
      if (!isStrongPassword(passwordRef.current?.value as string)) {
        alert(
          "Your password is too weak. Please change your password to a stronger one from your profile.",
        );
        router.push("/");
      }
    }
  };

  const forgotPassword = async () => {
    const email = forgotPasswordEmailRef.current?.value;

    if (email === null || email === "") {
      setHelperText({ error: true, text: "You must enter your email." });
    } else {
      const { error } = await supabase.auth.signInWithOtp({
        email: email as string,
        options: {
          shouldCreateUser: false,
          emailRedirectTo: process.env.NEXT_PUBLIC_SITE_URL + "/profile",
        },
      });
      if (error) {
        if (error.message === "Signups not allowed for otp") {
          setHelperText({
            error: true,
            text: "User not found. Please sign up.",
          });
        }
        console.error("Error: ", error.message);
      } else {
        setHelperText({
          error: false,
          text: "A one time password code has been sent to your email. Please follow the instructions in the email to login.",
        });
        setIsDialogOpen(false);
        setIsConfirmationDialogOpen(true);
      }
    }
  };

  const isStrongPassword = (password: string): boolean => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasSpecialChars = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    return (
      password.length >= minLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumbers &&
      hasSpecialChars
    );
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setHelperText({ error: false, text: "" });
  };

  const closeConfirmationDialog = () => {
    setIsConfirmationDialogOpen(false);
  };

  const renderForgotPasswordDialog = () => (
    <Dialog open={isDialogOpen} onClose={closeDialog}>
      <DialogTitle>Forgot Password</DialogTitle>
      <DialogDescription>
        Please enter your email to receive a one time login code.
      </DialogDescription>
      <DialogBody>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            forgotPassword();
          }}
        >
          <Input
            type="email"
            placeholder="Email"
            ref={forgotPasswordEmailRef}
          />
          <DialogActions>
            <button
              onClick={() => closeDialog()}
              className="mr-2 rounded-md bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              Cancel
            </button>
            <Button type="submit" color="green">
              Send Code
            </Button>
          </DialogActions>
        </form>
      </DialogBody>
    </Dialog>
  );

  const renderConfirmationDialog = () => (
    <Dialog open={isConfirmationDialogOpen} onClose={closeConfirmationDialog}>
      <DialogTitle>Email Sent</DialogTitle>
      <DialogDescription>
        A one time password code has been sent to your email. Please follow the
        instructions in the email to login.
      </DialogDescription>
      <DialogActions>
        <Button onClick={closeConfirmationDialog} color="green">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      <form
        className="mx-auto grid w-[350px] space-y-6"
        onSubmit={(e) => {
          e.preventDefault();
          handleLogin();
        }}
      >
        <div className="flex flex-col items-center justify-center">
          <h1 className="text-center text-2xl font-bold text-gray-800">
            Sign In
          </h1>
          <Text className="text-center text-gray-500">
            Sign in to your account.
          </Text>
        </div>
        <Fieldset>
          <FieldGroup>
            <Field>
              <Label htmlFor="email">Email address</Label>
              <Input
                id="email"
                name="email"
                type="email"
                title="Please enter your email"
                ref={emailRef}
                autoComplete="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
              />
            </Field>
            <Field>
              <Label htmlFor="password">Password</Label>
              <Input
                id="password"
                name="password"
                type="password"
                title="Please enter a password"
                ref={passwordRef}
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                required
              />
            </Field>
          </FieldGroup>
        </Fieldset>
        <div className="flex items-center justify-between">
          <Field className="mt-4 flex items-center justify-between">
            <SwitchField className="flex flex-row-reverse items-center">
              <Switch name="remember-me" color="indigo" />
              <Label className="text-sm font-medium">Remember me</Label>
            </SwitchField>
          </Field>
          <Field className="mt-4 flex items-center justify-between">
            <div className="text-sm">
              <a
                onClick={() => {
                  setIsDialogOpen(true);
                  setHelperText({ error: false, text: "" });
                }}
                className="cursor-pointer font-semibold text-indigo-600 hover:text-indigo-500"
              >
                Forgot password?
              </a>
            </div>
          </Field>
        </div>

        <SubmitButton
          title="Sign In"
          color="orange"
          loading={loadingSignIn}
          className="w-full"
        />
        <Notice
          type={helperText.error ? "error" : ""}
          title={helperText.error ? "Error logging in" : ""}
          message={helperText.text}
        />
      </form>
      {renderForgotPasswordDialog()}
      {renderConfirmationDialog()}
    </>
  );
};

export default Login;
